.header-view {
	background: #050041;
	.header {
		height: 70px;
		padding-left: 34px;
		padding-right: 48px;
		.menuNavList {
			.menuItem {
				margin-left: 76px;
				line-height: 70px;
				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
	.enterApp {
		width: 156px;
		height: 48px;
		border: #BC7B31 2px solid;
		&:hover {
			cursor: pointer;
		}
	}
}

.MuiLink-root.active {
	text-decoration: none!important;
}

@media only screen and (max-width: 1020px) {
	.header-view {
		background: transparent;
		.header-m {
			height: 88px;
			padding-left: 20px;
			padding-right: 24px;
			.header-r {

			}
		}
		.enterApp {
			width: 90px;
			height: 40px;
		}
	}
	.menu-container {
		width: 100vw;
		height: 100vh;
		background: url(../../assets/images//menuBg.png);
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		.menuListTab {
			margin-top: 56px;
			.menuItemTab {
				display: block;
				width: 300px;
				height: 48px;
				line-height: 48px;
				background: #021C4D;
			}
		}
		.close {
			position: absolute;
			bottom: 44px;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

@media only screen and (max-width: 321px) {
	.header-view {
		.header-m {
			padding-left: 10px;
			padding-right: 12px;
			.logo {
				height: 48px;
			}
		}
		.enterApp {
			width: 80px;
		}
	}
}