.footer-view {
	.footer {
		flex-wrap: wrap;
		height: 118px;
		width: 100vw;
		padding-left: 56px;
		padding-right: 74px;
		background: linear-gradient(180deg, #000429 0%, rgba(0, 4, 41, 0) 100%);
		.container {
			height: 100%;
		}
	}
}

@media only screen and (max-width: 1020px) {
	.footer-view {
		.container {
			flex-direction: column;
			justify-content: flex-start;
			.logo{ 
				margin-top: 10px;
			}
			.footer-r {
				margin-top: 14px;
			}
		}
	}
}