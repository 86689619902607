* {
  outline: none;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

html,
body,
#root {
  font-family: "Futura-Medium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  box-sizing: border-box;
  word-break: break-all;
}

#root {
  position: relative;
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  z-index: 1;
  background-size: cover;
  color: #FFFFFF;
  font-family: "Futura-Medium";
  transition: all ease 0.33ms;
}

.MuiButtonBase-root {
	display: flex;
	align-items: center;
}

.container {
  width: 1440px;
  max-width: 100%;
}

.container2 {
  width: 1020px;
  max-width: 100%;
}

.font-family-Futura-Bold {
	font-family: "Futura-Bold";
}

.font-family-Futura-Medium-Italic {
	font-family: "Futura-Medium-Italic";
}

.font-family-Futura-Medium {
	font-family: "Futura-Medium";
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-14 {
  margin-left: 14px;
}

.ml-18 {
  margin-left: 18px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-40 {
  margin-top: 40px;
}

.font-12 {
	font-size: 12px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.font-30 {
  font-size: 30px;
}

.font-36 {
  font-size: 36px;
}

.font-48 {
  font-size: 48px;
}

.color1 {
  color: #FFAD4E;
}

.color2 {
  color: rgba(255, 255, 255, 0.5);
}

.color3 {
  color: #533200;
}

.color4 {
  color: #FFC300;
}

.color5 {
  color: #01F7FF;
}

.color6 {
  color: #FFFFFF;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.line {
  width: 100px;
  height: 2px;
  background: #D8D8D8;
}

.bg1 {
  background: rgba(0, 0, 0, 0.4);
}

.bg2 {
  background: rgba(0, 0, 0, 0.6);
}

.bg3 {
  background: rgba(0, 0, 0, 0.2);
}

.bg4 {
  background: rgba(176, 117, 0, 0.8);
}

.bg5 {
  background: rgba(0, 0, 0, 0.8);
}

.bg6 {
  background: rgba(153, 0, 255, 0.6);
}

.text-center {
  text-align: center;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

@media only screen and (max-width: 1020px) {
  .container2 {
    width: 500px;
  }
}