.menuList {
    .menuItem {
        margin-left: 22px;
        width: 160px;
        height: 70px;
        padding: 0 10px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 35px;
        &:first-child {
            margin-left: 0;
        }
        .menuItemContainer {
            height: 100%;
        }
    }
    .menuItemActive {
        background: linear-gradient(131deg, #006BED 0%, #00479E 100%);
    }
}

.MuiLink-root.active {
	text-decoration: none;
}

@media only screen and (max-width: 1020px) {
    .menuList {
        flex-wrap: wrap;
        justify-content: center;
        .menuItem {
            margin-top: 10px;
            margin-left: 10px;
            width: 160px;
            height: 50px;
            border-radius: 25px;
            &:nth-child(2n+1) {
                margin-left: 0;
            }
        }
    }
}
